import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/models/category';
import { Order } from 'src/app/models/order';
import { OrderExtra } from 'src/app/models/order-extra';
import { Product } from 'src/app/models/product';
import { ShoppingBag } from 'src/app/models/shopping-bag';
import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';
import { fade } from 'src/app/utils/animations';
import * as styler from 'src/app/utils/styler';
import { ExtraCategory } from 'src/app/models/extra-category';
import { ExtraOption } from 'src/app/models/extra-option';
import { Swiper, SwiperOptions } from 'swiper/types';
import { firstValueFrom } from 'rxjs';
import { Inventory } from 'src/app/models/inventory';
interface CategoryWithExtras {
  category: ExtraCategory;
  extras: ExtraOption[];
  selectedExtrasCount: number;
  conditionMet: boolean;
}
interface SelectedExtra {
  _id: string;
  name: string;
  price: number;
  quantity: number;
  product_ref: string;
  category_ref: string;
}
interface SelectedOption {
  optionType: string;
  option: number;
  optionValue: string;
}
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  animations: [fade],
})
export class ProductComponent implements OnInit {
  styler = styler;
  product = {} as Product;
  loading = true;
  quantity = 1;
  page: number = 0;
  cid: string = '';
  sort: string = '';
  activeImageUrl: string = '';
  @ViewChild('swiper') swiperElement!: any;
  swiper!: Swiper;
  categoriesWithExtras = [] as CategoryWithExtras[];
  selectedExtras: SelectedExtra[] = [];
  selectedOptions: SelectedOption[] = [];
  hasStock: boolean = false;
  hasVariant: boolean = false;
  commentary: string = '';
  ref_variant: string = '';
  raw_value: string = '';
  variantStock: number = 0;
  activeExtraCategory: string = '';
  config: SwiperOptions = {
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    cssMode: true,
    spaceBetween: 0,
    slidesPerView: 1,
    injectStylesUrls: ['src/styles/swiper.scss'],
    injectStyles: [
      `
      .swiper-button-next, .swiper-button-prev {
        color: var(--primary-color);
      }
      swiper-slide{
        display: flex;
        justify-content: center;
      }
      .swiper-pagination {
        position: absolute !important;
        bottom: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
         .swiper-pagination-bullet-active {
          background-color: var(--primary-color);
          width: 30px !important;
          border-radius: 15px;
        }
        .swiper-pagination-bullet {
          height: 10px !important;
          width: 10px;
          transition: all ease-in 200ms;
          }
        }
        `,
    ],
  };
  constructor(
    private api: ApiService,
    private local: LocalService,
    private router: Router,
    private route: ActivatedRoute,
    private notyf: NotyfService
  ) {}

  ngOnInit(): void {
    this.loadAsyncData();
  }
  ngAfterViewInit() {
    document.querySelector('img')?.addEventListener('complete', () => {});
  }
  async loadAsyncData() {
    try {
      const params = await firstValueFrom(this.route.params);
      const queryParams = await firstValueFrom(this.route.queryParams);
      this.page = queryParams['page'];
      this.cid = queryParams['cid'];
      this.sort = queryParams['sort'];
      this.categoriesWithExtras = [];
      this.loading = true;
      this.activeImageUrl = '';
      this.product = {} as Product;
      this.product = await this.api.getProduct(params['id']);
      this.activeImageUrl = this.product.image_url[0];
      if (this.product.variants.length > 0) {
        this.hasVariant = true;
        this.product.price = 0;
        this.product.options.forEach((optionType) => {
          switch (optionType.name) {
            case 'SIZE':
              optionType.name = 'TALLA';
              break;
            case 'STYLE':
              optionType.name = 'ESTILO';
              break;
          }
          this.addOption(
            optionType.Options[0].id,
            optionType.name,
            optionType.Options[0].value
          );
        });
      }
      const filteredCategories = this.product.categories.filter(
        (cat) => cat.active
      );
      this.product.categories = filteredCategories;
      const inventory = this.local.getValue('inventory') as Inventory;
      if (this.product.extraCategories) {
        this.product.extraCategories.forEach((category) => {
          let categoryWithExtras = {
            category: category,
            extras: category.extras,
            selectedExtrasCount: 0,
          } as CategoryWithExtras;
          if (
            category.settings.find(
              (setting) =>
                setting.active === true &&
                setting.inventory_id === inventory?.id
            )
          ) {
            this.categoriesWithExtras.push(categoryWithExtras);
          }
        });
        this.categoriesWithExtras = this.categoriesWithExtras.sort((a, b) => {
          return b.category.importance - a.category.importance;
        });
      }

      this.loading = false;
      setTimeout(() => {
        this.swiper = this.swiperElement.nativeElement.swiper;
      }, 100);
    } catch (error) {
      this.notyf.error(error);
      this.loading = false;
    }
  }

  isActive(extra: ExtraOption): boolean {
    if (
      extra.settings.find(
        (setting) =>
          setting.active === true &&
          setting.inventory_id === this.local.getValue('inventory').id
      )
    ) {
      return true;
    }
    return false;
  }

  //validar que la config. del producto este correcta
  checkConditions(): CategoryWithExtras {
    this.categoriesWithExtras.forEach((categoryWithExtra) => {
      //eliminar styling de error
      document
        .getElementById(categoryWithExtra.category._id)
        ?.classList.remove('!border-red-400');
      document
        .getElementById(categoryWithExtra.category._id + '-arrow')
        ?.classList.remove('hidden');
      document
        .getElementById(categoryWithExtra.category._id + '-info')
        ?.classList.remove('block');
      document
        .getElementById(categoryWithExtra.category._id + '-info')
        ?.classList.add('hidden');
      //revisar errores
      if (categoryWithExtra.category.settings) {
        if (categoryWithExtra.category.max === categoryWithExtra.category.min) {
          if (
            categoryWithExtra.category.max ===
            categoryWithExtra.selectedExtrasCount
          ) {
            categoryWithExtra.conditionMet = true;
          } else {
            categoryWithExtra.conditionMet = false;
          }
        } else if (
          categoryWithExtra.category.max > categoryWithExtra.category.min
        ) {
          if (
            categoryWithExtra.category.min <=
            categoryWithExtra.selectedExtrasCount
          ) {
            categoryWithExtra.conditionMet = true;
          } else {
            categoryWithExtra.conditionMet = false;
          }
        } else if (categoryWithExtra.category.max === null) {
          categoryWithExtra.conditionMet = true;
        }
      }
    });
    const invalidCategoryWithExtras: CategoryWithExtras =
      this.categoriesWithExtras.find(
        (cat) => !cat.conditionMet
      ) as CategoryWithExtras;
    return invalidCategoryWithExtras;
  }

  getExtraAmmount(extra_ref: string, category_ref: string): number {
    return (
      this.selectedExtras.find(
        (e) => e._id === extra_ref && e.category_ref === category_ref
      )?.quantity ?? 0
    );
  }

  getOrderExtras(): OrderExtra[] {
    let orderExtras: OrderExtra[] = [];
    this.selectedExtras.forEach((e) => {
      for (let index = 0; index < e.quantity; index++) {
        let orderExtra = {
          ref_extra: e._id,
          ref_category: e.category_ref,
        } as OrderExtra;
        orderExtras.push(orderExtra);
      }
    });
    return orderExtras;
  }

  getOrderExtrasDetails(orderExtras: OrderExtra[]): ExtraOption[] {
    let extras: ExtraOption[] = [];
    let final: ExtraOption[] = [];

    // Load Extras
    this.categoriesWithExtras.forEach((item) => {
      item.extras.forEach((extra) => {
        extra.category_ref = item.category._id;
        if (extras.indexOf(extra) === -1) extras.push(extra);
      });
    });

    // Load ExtraSelected
    orderExtras.forEach((orderExtra) => {
      const extra = extras.find(
        (extra) =>
          extra._id === orderExtra.ref_extra &&
          extra.category_ref === orderExtra.ref_category
      );
      if (extra) final.push(extra);
    });

    return final;
  }

  addQuantity(): void {
    if (this.hasVariant) {
      if (this.quantity < this.variantStock) {
        this.quantity = this.quantity + 1;
      }
    } else {
      this.quantity = this.quantity + 1;
    }
  }

  //agregar producto al carrito
  addToShoppingBag(): void {
    if (this.hasVariant && !this.hasStock) {
      this.notyf.simpleError('No hay stock para esa combinación.');
    } else {
      let categoryWithExtra: CategoryWithExtras = this.checkConditions();
      if (categoryWithExtra) {
        //mostrar error
        this.showValidationError(categoryWithExtra);
      } else {
        //agregar al carrito
        if (!this.local.getValue('shoppingBag')) {
          const shoppingBag = {
            orders: [],
            timestamp: new Date().getTime(),
          };
          this.local.setValue('shoppingBag', shoppingBag);
        }

        let shoppingBag = this.local.getValue('shoppingBag') as ShoppingBag;

        let order: Order = {
          ProductId: this.product.id,
          product_Id: this.product._id,
          price: this.calculateProductTotal(),
          quantity: this.quantity,
          order_extras: this.getOrderExtras() ?? [],
          commentary: this.commentary,
          product: this.product,
          extras: this.getOrderExtrasDetails(this.getOrderExtras()),
          OrderVariants: [],
        } as Order;
        if (this.hasVariant) {
          order.OrderVariants?.push({
            ref_variant: this.ref_variant,
            raw_value: this.raw_value,
          });
        }
        let existingOrder: boolean =
          shoppingBag.orders.filter((o) => o.ProductId === order.ProductId)
            .length > 0
            ? true
            : false;
        if (
          order.order_extras.length > 0 ||
          !existingOrder ||
          this.hasVariant
        ) {
          //agregar al carrito como una orden separada
          shoppingBag.orders.push(order);
        } else {
          //subir la cantidad de la orden existente en el carrito
          shoppingBag.orders.forEach((o) => {
            if (o.ProductId === order.ProductId) {
              o.quantity = o.quantity + order.quantity;
            }
          });
        }
        this.local.setValue('shoppingBag', shoppingBag);
        this.local.updateQuantitySubject();
        this.local.updateCartSubject();
        this.notyf.success('Producto agregado con exito! 👌');
        this.router.navigate(['/productos'], {
          queryParams: { cid: this.cid, sort: this.sort, page: this.page },
        });
      }
    }
  }
  //mostrar categoria de extra que presenta error
  showValidationError(categoryWithExtra: CategoryWithExtras): void {
    this.styler.toggleExtraCategoryError(categoryWithExtra.category._id);
    this.notyf.simpleError('Debes completar las opciones obligatorias.');
  }

  addOption(optionId: number, optionTypeName: string, optionValue: string) {
    const filteredSelectedOptions = this.selectedOptions.filter(
      (o) => o.optionType != optionTypeName
    );
    filteredSelectedOptions.push({
      option: optionId,
      optionValue: optionValue,
      optionType: optionTypeName,
    });
    this.selectedOptions = filteredSelectedOptions;
    if (this.selectedOptions.length === this.product.options.length) {
      this.checkVariantStock();
    }
  }

  checkVariantStock() {
    let raw_value = '';
    const orderedOptions = this.selectedOptions.sort(function (a, b) {
      return a.option - b.option;
    });
    const optionIds: number[] = [];
    orderedOptions.forEach((op) => {
      if (raw_value === '') {
        raw_value = raw_value + op.optionValue;
      } else {
        raw_value = raw_value + ' / ' + op.optionValue;
      }
      optionIds.push(op.option);
    });
    this.product.variants.forEach((variant) => {
      const combinationIds = variant.combination_id.sort(function (a, b) {
        return a - b;
      });
      const equalValues =
        optionIds.length === combinationIds.length &&
        optionIds.every((value, index) => value === combinationIds[index]);
      if (equalValues) {
        if (variant.image_url) {
          this.activeImageUrl = variant.image_url;
          if (!this.loading) {
            const imgIndex = this.product.image_url.indexOf(variant.image_url);
            if (imgIndex >= 0) {
              this.swiper.slideTo(imgIndex);
            } else {
              this.swiper.slideTo(0);
            }
          }
        } else {
          this.activeImageUrl = this.product.image_url[0];
          if (!this.loading) {
            this.swiper.slideTo(0);
          }
        }
        if (variant.stock <= 0) {
          this.hasStock = false;
          this.product.price = 0;
          this.variantStock = 0;
          this.ref_variant = '';
          this.raw_value = '';
        } else {
          this.hasStock = true;
          this.product.price = variant.price;
          this.ref_variant = variant._id;
          this.raw_value = raw_value;
          this.variantStock = variant.stock;
        }
      }
    });
  }
  //agregar extra
  addExtra(
    extra: ExtraOption,
    category_ref: string,
    method: string,
    remove?: boolean
  ): void {
    if (method === 'radio') {
      if (this.selectedExtras.find((e) => e.category_ref === category_ref)) {
        this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
          .selectedExtrasCount--;
      }
      this.selectedExtras = this.selectedExtras.filter(
        (e) => e.category_ref !== category_ref
      );

      let selectedExtra = {
        _id: extra._id,
        category_ref: category_ref,
        name: extra.name,
        price: extra.price,
        quantity: 1,
        product_ref: this.product._id,
      } as SelectedExtra;
      this.selectedExtras.push(selectedExtra);
      this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
        .selectedExtrasCount++;
    } else if (method === 'checkbox') {
      if (
        this.selectedExtras.find(
          (e) => e._id === extra._id && e.category_ref === category_ref
        )
      ) {
        // this.selectedExtras = this.selectedExtras.filter(
        //   (e) => e._id !== extra._id
        // );
        this.selectedExtras = this.selectedExtras.filter((e) => {
          if (e._id === extra._id && e.category_ref === category_ref)
            return false;
          else return true;
        });
        this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
          .selectedExtrasCount--;
      } else {
        let selectedExtra = {
          _id: extra._id,
          category_ref: category_ref,
          name: extra.name,
          price: extra.price,
          quantity: 1,
          product_ref: this.product._id,
        } as SelectedExtra;
        this.selectedExtras.push(selectedExtra);
        this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
          .selectedExtrasCount++;
      }
    } else {
      //Counter
      if (remove) {
        //eliminar extra
        //if(this.selectedExtras.filter((e) => e._id === extra._id && e.category_ref === category_ref)[0].quantity > 1)
        if (
          this.selectedExtras.find(
            (e) => e._id === extra._id && e.category_ref === category_ref
          )!.quantity > 1
        )
          this.selectedExtras.find(
            (e) => e._id === extra._id && e.category_ref === category_ref
          )!.quantity--;
        else
          this.selectedExtras = this.selectedExtras.filter((e) => {
            if (e._id === extra._id && e.category_ref === category_ref)
              return false;
            else return true;
          });

        this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
          .selectedExtrasCount--;
      } else {
        let selectedExtra = {
          _id: extra._id,
          category_ref: category_ref,
          name: extra.name,
          price: extra.price,
          quantity: 1,
          product_ref: this.product._id,
        } as SelectedExtra;
        //agregar extra
        this.selectedExtras.find(
          (e) => e._id === selectedExtra._id && e.category_ref === category_ref
        )
          ? this.selectedExtras.find(
              (e) =>
                e._id === selectedExtra._id && e.category_ref === category_ref
            )!.quantity++
          : this.selectedExtras.push(selectedExtra);

        this.categoriesWithExtras.find((c) => c.category._id === category_ref)!
          .selectedExtrasCount++;
      }
    }
    this.checkConditions();
  }

  //retornar costo total del producto
  calculateProductTotal(): number {
    let extrasPrice = 0;
    this.selectedExtras.length > 0
      ? this.selectedExtras.forEach((selectedExtra) => {
          extrasPrice =
            extrasPrice + selectedExtra.price * selectedExtra.quantity;
        })
      : (extrasPrice = 0);
    return this.product.price + extrasPrice;
  }

  //retornar los extras seleccionados de cierta categoria
  getExtraCategorySelectedExtras(category_ref: string): SelectedExtra[] {
    return this.selectedExtras.filter((e) => e.category_ref === category_ref);
  }

  //retornar cantidad de extras seleccionados de cierta categoria
  getExtraCategorySelectedExtrasQuantity(category_ref: string): number {
    let quantity = 0;
    this.selectedExtras
      .filter((e) => e.category_ref === category_ref)
      .forEach((selectedExtra) => {
        quantity = quantity + selectedExtra.quantity;
      });
    return quantity;
  }

  //retornar tamaño de la imagen a renderizar
  getImageRenderSize(): string {
    if (window.innerWidth <= 1440) {
      return '386';
    } else {
      return '500';
    }
  }
}
