import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/models/order';
import { OrderExtra } from 'src/app/models/order-extra';
import { Product } from 'src/app/models/product';
import { ShoppingBag } from 'src/app/models/shopping-bag';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';

@Component({
  selector: 'app-carousel-product-card',
  templateUrl: './carousel-product-card.component.html',
  styleUrls: ['./carousel-product-card.component.scss'],
})
export class CarouselProductCardComponent {
  @Input() product!: Product;
  showCounter = false;
  count = 0;
  shoppingBag!: ShoppingBag;
  bagSubscription!: Subscription;
  constructor(
    private router: Router,
    private local: LocalService,
    private notyf: NotyfService
  ) {}

  ngOnInit() {
    document
      .getElementById(this.product._id)
      ?.addEventListener('complete', () => {});
    this.loadLocalData();
  }
  loadLocalData() {
    this.shoppingBag = this.local.getValue('shoppingBag');
    this.bagSubscription = this.local.cartSubject.subscribe((bag) => {
      this.shoppingBag = bag;
      this.count = bag.orders.filter(
        (order) =>
          order.product.id === this.product.id &&
          order.extras.length === 0 &&
          !this.product.hasVariants
      )[0]?.quantity;
      if (this.count === null || this.count === undefined) {
        this.count = 0;
        this.showCounter = false;
      }
    });
    this.count =
      this.shoppingBag?.orders?.filter(
        (order) =>
          order.ProductId === this.product.id &&
          order.extras.length === 0 &&
          !this.product.hasVariants
      )[0]?.quantity ?? 0;
    if (this.count > 0) {
      this.showCounter = true;
    }
  }
  add(product: Product) {
    this.count++;
    /** Logica para agregar al carrito */
    if (!this.local.getValue('shoppingBag')) {
      const shoppingBag = {
        orders: [],
        timestamp: new Date().getTime(),
      };
      this.local.setValue('shoppingBag', shoppingBag);
    }

    let shoppingBag: ShoppingBag = this.local.getValue('shoppingBag');

    let order: Order = {
      ProductId: product.id,
      product_Id: product._id,
      price: product.price,
      quantity: 1,
      order_extras: [] as OrderExtra[],
      commentary: '',
      product: product,
      extras: [],
      OrderVariants: [],
    } as Order;
    let existingOrder: boolean =
      shoppingBag.orders.filter((o) => o.ProductId === order.ProductId).length >
      0
        ? true
        : false;
    if (!existingOrder) {
      //agregar al carrito como una orden separada
      shoppingBag?.orders.push(order);
    } else {
      //subir la cantidad de la orden existente en el carrito
      shoppingBag.orders.forEach((o) => {
        if (o.ProductId === order.ProductId) {
          o.quantity = o.quantity + order.quantity;
        }
      });
    }
    this.shoppingBag = shoppingBag;
    this.local.setValue('shoppingBag', shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
    if (this.count === 1) this.notyf.success('Producto agregado con exito! 👌');
  }

  remove(product: Product) {
    if (this.count === 1) {
      this.showCounter = false;
    }
    this.count--;
    /** Logica para remover del carrito TODO */
    if (this.count === 0) {
      /** Eliminar del carrito */
      const order = this.shoppingBag.orders.filter(
        (order) => order.ProductId === product.id
      )[0];
      this.shoppingBag.orders.splice(this.shoppingBag.orders.indexOf(order), 1);
      this.local.setValue('shoppingBag', this.shoppingBag);
    } else {
      /** Disminuir quantity */
      this.shoppingBag.orders.filter(
        (order) => order.ProductId === product.id
      )[0].quantity = this.count;
      this.local.setValue('shoppingBag', this.shoppingBag);
    }
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }

  addProduct(product: Product) {
    if (product.extraCategories.length > 0 || product.hasVariants) {
      this.router.navigate(['/productos/' + product._id]);
    } else {
      this.showCounter = true;
      this.add(product);
    }
  }
}
