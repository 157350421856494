import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Business } from 'src/app/models/business';
import { Order } from 'src/app/models/order';
import { PaymentLink } from 'src/app/models/payment-link';
import { Settings } from 'src/app/models/settings';
import { ShoppingBag } from 'src/app/models/shopping-bag';
import { ApiService } from 'src/app/services/api.service';
import { LocalService } from 'src/app/services/local.service';
import { NotyfService } from 'src/app/services/notyf.service';
import * as dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SwalService } from 'src/app/services/swal.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-shopping-bag',
  templateUrl: './shopping-bag.component.html',
  styleUrls: ['./shopping-bag.component.scss'],
})
export class ShoppingBagComponent implements OnInit {
  animateClose = false;
  shoppingBag!: ShoppingBag;
  loading = false;
  business!: Business;
  settings!: Settings;
  @Output() closeEmit = new EventEmitter<void>();

  constructor(
    private local: LocalService,
    private notyf: NotyfService,
    private api: ApiService,
    private swal: SwalService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit(): void {
    this.loadLocalData();
  }
  closeBag(): void {
    this.animateClose = true;
    setTimeout(() => {
      this.closeEmit.emit();
    }, 250);
  }

  add(order: Order) {
    order.quantity++;
    this.local.setValue('shoppingBag', this.shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }

  remove(order: Order) {
    order.quantity--;
    if (order.quantity === 0) {
      this.shoppingBag.orders.splice(this.shoppingBag.orders.indexOf(order), 1);
    }
    this.local.setValue('shoppingBag', this.shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }

  getSubtotal() {
    let total = 0;
    this.shoppingBag.orders.forEach((order) => {
      total = total + order.price * order.quantity;
    });
    return total;
  }

  emptyCart() {
    this.shoppingBag.orders = [];
    this.local.setValue('shoppingBag', this.shoppingBag);
    this.local.updateQuantitySubject();
    this.local.updateCartSubject();
  }

  loadLocalData() {
    this.shoppingBag = this.local.getValue('shoppingBag');
    this.shoppingBag.orders.forEach((order) => {
      document
        .getElementById(order.product._id)
        ?.addEventListener('complete', () => {});
    });
    this.settings = this.local.getValue('settings');
    this.business = this.local.getValue('business');
  }

  async cartChecker(orders: Order[], hasVariants: boolean) {
    if (hasVariants) {
      const checkStock = await this.api.checkStock(orders);
      if (!checkStock.success) return checkStock.message;
    }
    const check = await this.api.checkAvailableProducts(
      this.shoppingBag.orders,
      this.settings.inventory_id
    );
    if (check.valid) {
      return true;
    } else {
      return false;
    }
  }

  async processCart(isDelivery: boolean) {
    try {
      this.loading = true;
      let totalPrice = 0;
      let hasVariants = false;
      this.shoppingBag.orders.forEach((o) => {
        totalPrice = totalPrice + o.price * o.quantity;
        if (o.OrderVariants.length > 0) {
          hasVariants = true;
        }
      });
      const { open, will_open } = await this.api.getStatusSchedule(
        this.local.getValue('inventory').id
      );
      if (this.business.paused) {
        this.notyf.simpleError('En estos momentos estamos cerrados.');
        this.loading = false;
      } else if (
        (this.settings.scheduledOrdersDisabled && !open) ||
        (!this.settings.scheduledOrdersDisabled &&
          this.settings.scheduledSameDay &&
          !will_open &&
          !open)
      ) {
        this.notyf.simpleError('En estos momentos estamos cerrados.');
        this.loading = false;
      } else if (totalPrice < this.settings.minimumOrder) {
        this.notyf.simpleError(
          'El monto minimo de la orden debe ser de $' +
            this.settings.minimumOrder
        );
        this.loading = false;
      } else if (
        this.settings.deliveryDisabled &&
        this.settings.pickupDisabled
      ) {
        this.notyf.simpleError(
          'En estos momentos no estamos procesando ordenes.'
        );
        this.loading = false;
      } else {
        const validCart = await this.cartChecker(
          this.shoppingBag.orders,
          hasVariants
        );
        if (validCart && typeof validCart !== 'string') {
          const primaryColor = getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary-color');

          const expirationDate = dayjs().add(2, 'hours');
          const paymentLink = {
            link_src: 'FRONTOFFICE',
            referer_url: window.location.origin,
            shopping_bag: this.shoppingBag.orders,
            expiration_date: expirationDate.unix() * 1000,
            link_type: 'UNIQUE',
            inventory_id: this.local.getValue('inventory').id,
            theme_color: primaryColor,
            isDelivery: isDelivery,
          } as PaymentLink;
          const response = await this.api.createPaymentLink(paymentLink);
          window.location.href = environment.payUrl + `${response.link._id}`;
        } else {
          // Se muestra el mensaje si validCart no regreso falso
          this.notyf.simpleError(
            validCart ||
              'Lamentablemente uno de los productos ya no se encuentra disponible.'
          );
          this.loading = false;
        }
      }
    } catch (error) {
      this.notyf.error(error, error);
    }
  }

  async emptyCartConfirmBox() {
    if (this.shoppingBag.orders.length > 0) {
      const result = await this.swal.showDialog(
        '¿Estás seguro?',
        'Al vaciar tu carrito se pierden todos los productos agregados',
        'warning'
      );
      if (result.value) {
        this.emptyCart();
      }
    }
  }
}
